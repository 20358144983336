import React from "react"
import { graphql } from "gatsby"
import {AiFillTwitterSquare, AiFillLinkedin, AiOutlineMail} from "react-icons/ai";
import Layout from "../components/layout"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query ContactQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			html
			excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        title
        twitterUsername
        linkedInProfile
        emailAddress
      }
    }
  }
`

const Contact = ({data}) => {
  const { markdownRemark, site } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return  (
    <Layout className="contact-page">
      <SEO 
        title={frontmatter.title}
        description={frontmatter.title + " " + site.siteMetadata.title}
      />
      <div className="wrapper">
        <h1>{frontmatter.title}</h1>
        <div className="description" dangerouslySetInnerHTML={{ __html: html }} />
        <p>
          <a href="mailto:andy@thunk.technology">
            <span className="icon -right"><AiOutlineMail/></span>&nbsp;Email
          </a>
        </p>
        <p>
          <a href={`https://twitter.com/${site.siteMetadata.twitterUsername}`}>
            <span className="icon -right"><AiFillTwitterSquare/></span>&nbsp;Twitter
          </a>
        </p>
        <p>
          <a href={`${site.siteMetadata.linkedInProfile}`}>
            <span className="icon -right"><AiFillLinkedin/></span>&nbsp;LinkedIn
          </a>
        </p>
      </div>

    </Layout>
  )
}

export default Contact